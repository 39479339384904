@import "jsoneditor/dist/jsoneditor.min.css";

body {
    background: url('./assets/bg_1.jpg');
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}
